<template>
  
  <div class="about-vivian">
    <div class="container py-5">
        <h1 class="mb-4">קורותיה של ויוי שפירא</h1>
        <p>אחות, אמא, סבתא וחברה יקרה. אמא ויוי נולדה בעיירה חקלאית בדרום אפריקה, Kroonstaad, לאבא ג'ואל ואימא לילי, אחות צעירה למרילין, טוני ושלושה כלבים. אחותה מרילין היתה גדולה ממנה ב-12 שנים ואמא היתה מספרת עליה בהערצה, איך היתה נוהגת לקחת אותה לדייטים שלה ואפילו חשבה שמרילין היא אימה מרב מסירותה אליה. אחיה טוני היה נער מרדן ושובב, שהיה מתלה בה ועובד עליה אבל היה להם כיף מאוד ביחד ועד יומה האחרון היא התרגשה משיחות טלפון ומפגשים איתו.</p>
        <p>יום אחד כשטיילה לאורך הנהר הבוצי שחצה את העיירה אמא החליקה, נפלה לנהר, נלכדה בזרם התחתי ונעלמה מהעין. הכלב הגדול הצליח למצוא אותה ובעזרתו אחותה חילצה אותה מהמים, ומאז אימא לא הפסיקה לאסוף ולאמץ כלבים וחתולים.</p>
        <p>בקרונסטאד היה רק בית ספר אחד, מנוהל בידי נזירות קתוליות. לאמא היו זכרונות טובים משם והיא אף שמרה מגוון שירים ששרנו לאורך ילדותנו, בנסיעות ולפני השינה. אמא אהבה את המסורת היהודית והקפידה לחגוג ולהכיר את המנהגים. בביתנו תמיד חגגנו את כל החגים, בשילוב המסורות היהודיות תוך יצירת מנהגים משלנו שהושפעו מהקיבוץ ומדרום אפריקה.</p>
        <p>נישואיה הראשונים היו קצרים ואומללים, יום אחד אחיה טוני שאל "את מאושרת איתו?" - כשאמרה לא, באותו רגע הוא לקח אותה לארוז, לעזוב ובסופו של דבר להתגרש. אבא בריאן היה האהבה הגדולה של אמא. היא בחרה בו למרות שידעה על הקשיים שלו, היא האמינה שאהבה פותרת הכול. האהבה שלהם הייתה סוערת, מלאת צחוק, תשוקה, וגם ריבים – אבל אלה תמיד היו קצרים, אמא תמיד הכריחה את כולם להשלים ולסלוח לפני שהולכים לישון. ההחלטה לעלות לישראל ולהותיר מאחור את משפחתה קרעה את אמא, ובמשך שנים היא המשיכה להתכתב עם סבתא לילי מדי יום, בכתב מחובר מסולסל וסוער מגעגועים.</p>
        <p>כשההורים הגיעו לקיבוץ אבא בריאן לקח על עצמו את ריכוז המתנדבים, וככה ביתנו תמיד היה מחובר לאנשים מכל העולם. אמא נהנתה מזה, עזרה לו, וגם אחרי לכתו המשיכה בכל הזדמנות לארח אנשים רבים בביתנו וכך להכיר לנו תרבויות רבות וליצור חברויות ברחבי הגלובוס.</p>
        <p>ההורים שלנו תמיד נתנו לנו מאתיים אחוזים מזמנם ותשומת ליבם, והבית היה מלא צחוק, משחקים משותפים, אורחים וחיות. כשהייתי בן 10 אבי התחיל לכרוע תחת העול, וכעבור שנה הוא נכנע לשדים הפנימיים ועזב אותנו. אמא נותרה עם ארבעה ילדים בגילאים 11, 9, ארבע וחצי, ושנתיים, ללא משפחתה הקרובה. השנים שלאחר מכן היו איומות. מלאות כעס ופחדים. אבל אמא התגלתה כלביאה. היא הצליחה להחזיק את עצמה, להרים אותנו, ולתקן טעויות. היא לא נכנעה לאבל, ודאגה למלא את החיים שלנו בחוגים, טיולים, שמחות. אמא הצליחה להבין כל אחד מילדיה השונים כל-כך, להתאים את עצמה, ולעזור לנו למצוא את הדרך הנכונה עבורנו.</p>
        <p>אימא החליפה הרבה מקומות עבודה ותמיד מצאה משמעות וסיפוק בקירבה ונתינה. היא טיפלה בתינוקות, בזקנים, בילדים יתומים, ולבסוף התמקדה בלימוד אנגלית ובחינוך מיוחד בפרט. היה לה כישרון להתחבר לתלמידים שלה, הרבה פעמים האנגלית הייתה כלי אבל העבודה האמיתית הייתה לרפא את הלב. אם זה לשבת עם מישהו על הרצפה מתחת לשולחן כדי לצלוח ביחד התקף חרדה, או פשוט לדאוג שהאדם מולה ירגיש מיוחד ואהוב.</p>
        <p>אימא תמיד היתה בעשייה, כדרך קבע היא חיפשה להיות עם משפחה וחברים, התאמצה לשמור על קשרים, שמחה להכיר אנשים חדשים, ולהכין דברים, לבשל ולהתרגש. היא אהבה לתבל את חייה במיליון חוויות ובילויים, רצתה לגמוע ולנסות מהכל והצליחה ליצור מעגלי חברויות רבים.</p>
        <p>בשנים האחרונות הגוף של אמא התחיל להיסדק, אבל ברוחה היא נותרה ילדה קטנה. היא המשיכה להתרגש ולהתלהב בקלות, וצחקה בקול רם בקולנוע. הנכדים היו מפץ אור בחייה ומקור בלתי נדלה להרפתקאות, צחוק ואושר.</p>
        <p>משמעות השם ויויאן זה "alive", חיה. ואימא חיה בקצב כפול, עם כול הלב. יהי זכרה ברוך.</p>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'AboutVivian',
  });
  </script>
  
  <style scoped>
  .about-vivian {
    background-color: #f5f5f5;
    padding-bottom: 2rem;
    direction: rtl;
  }
  
  h1 {
    color: #333;
    text-align: center;
  }
  
  p {
    text-align: justify;
    line-height: 1.6;
  }
  </style>
  